import React from 'react';
import PropTypes from 'prop-types';

const Price = props => {
  const { price, ntSty, priceSty } = props;

  return (
    <span style={ntSty} className="nt">
      NT$
      <span style={priceSty} className="nt-p">{price}</span>
      <style jsx>{`
        .nt {
          font-size: 14px;
          font-family: SFProDisplay-Regular;
        }

        .nt-p {
          margin-left: 3px;
          font-size: 21px;
          font-family: SFProDisplay-Regular;
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </span>
  );
};

Price.propTypes = {
  price: PropTypes.number,
  ntSty: PropTypes.object,
  priceSty: PropTypes.object,
};

Price.defaultProps = {
  price: '',
  ntSty: {},
  priceSty: {},
};

export default Price;
