import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import colors from '../config/color';
import ShoppingCheckPriceFreight from '../components/shoppingCheckPriceFreight';
import ShoppingTotal from '../components/shoppingTotal';

const ShoppingTotalPriceRight = () => {
  return (
    <div className="content">
      <div className="total-price">
        <ShoppingCheckPriceFreight
          text="商品總金額"
          price="4000"
          ntSty={{ fontSize: 14 }}
          priceSty={{ fontSize: 14 }}
          freight="運費"
          priceFreight="60"
        />
        <hr />
        <ShoppingTotal
          text="總計"
          price="4060"
          ntSty={{ fontSize: 14 }}
          priceSty={{ fontSize: 14 }}
        />
      </div>

      <style jsx>{`
        .content {
          font-size: 14px;
        }

        hr {
          border: none;
          height: 1px;
          background-color: #e0e0e0;
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

ShoppingTotalPriceRight.propTypes = {
  title: PropTypes.string,
  showActionBtn: PropTypes.bool,
};

ShoppingTotalPriceRight.defaultProps = {
  title: '',
  showActionBtn: '',
};

export default ShoppingTotalPriceRight;
