import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ShoppingTitle from '../../components/shoppingTitle';
import ShoppingOrderDetails from '../../components/shoppingOrderDetails';
import ShoppingTotalPriceRight from '../../components/shoppingTotalPriceRight';
import ShoppingOrderPayment from '../../components/shoppingOrderPayment';
import ShoppingCheckPayTitle from '../../components/shoppingCheckPayTitle';

class Homepage extends Component {
  render() {
    // const price1 = 100;
    // const price2 = 100;
    // const sum = price1 + price2;
    return (
      <Layout showHeader={false} showHero={false}>
        {/* TODO: SEO */}
        <SEO title="Here Hear 聽聽" />
        {/* <div className="search-bar-up">
          <BigSearchBar categories={data.allWordpressCategory.edges} />
          <Product title={''} edges={data.allWordpressPost.edges} />
          <div className="more-btn-content">
            <Link style={{ textAlign: 'center' }} to={'/posts/page/1'}>
              <div className="more">看更多</div>
            </Link>
          </div>
          <Category edges={data.allWordpressCategory.edges} />
        </div> */}

        <div className="product-content">
          <ShoppingTitle title="訂單詳情" showActionBtn={true} />
          <div className="sty">
            <ShoppingOrderDetails number="1234567890" />
          </div>

          <div className="total-price">
            <ShoppingTotalPriceRight />
          </div>
          <div className="clear"></div>
          <div className="sty-top">
            <ShoppingOrderPayment
              pay="信用卡"
              addressee="某某某"
              address="106台北市松山區敦化南路一段"
            />
          </div>
          <div className="sty-top">
            <ShoppingCheckPayTitle titleRight="注意事項" titleLeft="" />
          </div>
        </div>

        <style jsx>{`
          .product-content {
            padding: 30px 0;
            margin: 0px 16px;
            min-width: 343px;
          }

          .sty {
            margin-top: 36px;
          }

          .sty-top {
            margin-top: 20px;
          }

          .total-price {
            width: 60%;
            float: right;
          }

          .clear {
            clear: right;
          }
        `}</style>
      </Layout>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    allWordpressCategory {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    hotCategory: allWordpressCategory(
      sort: { fields: count, order: DESC }
      limit: 8
    ) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressPost(limit: 20) {
      edges {
        node {
          title
          slug
          categories {
            wordpress_id
            id
            name
            slug
          }
          tags {
            id
            name
            slug
          }
          jetpack_featured_media_url
        }
      }
    }
  }
`;
