import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Price from './price';
import colors from '../config/color';

const ShoppingCheckPrice = props => {
  const { text, price, ntSty, priceSty } = props;

  return (
    <div className="content">
      <div className="info-content">
        <div className="text-content">{text}</div>
        <div className="text-content">
          <Price price={price} ntSty={ntSty} priceSty={priceSty} />
        </div>
      </div>

      <style jsx>{`
        .info-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
        }

        .text-content {
          color: ${colors['purple']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 14px;
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

ShoppingCheckPrice.propTypes = {
  text: PropTypes.string,
  price: PropTypes.number,
  ntSty: PropTypes.object,
  priceSty: PropTypes.object,
};

ShoppingCheckPrice.defaultProps = {
  title: '',
  price: '',
  ntSty: {},
  priceSty: {},
};

export default ShoppingCheckPrice;
