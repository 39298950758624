import React from 'react';
import { Link } from 'gatsby';
import PropTypes, { number } from 'prop-types';
import colors from '../config/color';
import ShoppingCheckPayTitle from './shoppingCheckPayTitle';

const ShoppingOrderPayment = props => {
  const { pay, addressee, address } = props;
  return (
    <div className="content">
      <ShoppingCheckPayTitle titleRight="付款方式及寄宿資料" titleLeft="" />
      <div className="info-content">付款方式：{pay}</div>
      <div className="info-content">收件人：　{addressee}</div>
      <div className="info-content">配送地址：{address}</div>
      <div className="info-content">
        發票：　　
        <a href className="invoice-content">電子發票</a>
      </div>

      <style jsx>{`
        .content {
          font-size: 14px;
          font-family: SFProDisplay-Regular;
          color: ${colors['black-p']};
        }

        .info-content {
          padding: 7px 0;
        }

        .invoice-content {
          color: ${colors['purple']};
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

ShoppingOrderPayment.propTypes = {
  pay: PropTypes.string,
  addressee: PropTypes.string,
  address: PropTypes.string,
};

ShoppingOrderPayment.defaultProps = {
  pay: '',
  addressee: '',
  address: '',
};

export default ShoppingOrderPayment;