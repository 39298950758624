import React from 'react';
import PropTypes from 'prop-types';
import colors from '../config/color';

const ShoppingCheckPayTitle = props => {
  const { titleRight, titleLeft } = props;

  return (
    <div className="content">
      <div className="title-content">
        <h2>{titleRight}</h2>
        <h2>{titleLeft}</h2>
      </div>
      <hr />

      <style jsx>{`
        .title-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        h2 {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 1px;
        }

        hr {
          border: none;
          height: 1px;
          background-color: ${colors['black-p']};
        }
        @media (min-width: 768px) {
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

ShoppingCheckPayTitle.propTypes = {
  titleRight: PropTypes.string,
  titleLeft: PropTypes.string,
};

ShoppingCheckPayTitle.defaultProps = {
  titleRight: '',
  titleLeft: '',
};

export default ShoppingCheckPayTitle;
